import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Amex from "../images/Americanexpress.svg"
import creditstrong from "../images/CreditStrong-fintech.svg"
import taxslayer from "../images/TaxSlayer-fintech.svg"
import Shapefour from "../images/bannerElement/white-design-element-fintech.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import creativeContent from "../images/creative-content.svg"
import CustomerAcquisition from "../images/icons/Fintech/CustomerAcquisition.svg"
import Differentiation from "../images/icons/Fintech/Differentiation.svg"
import HeavilyRegulatedIndustry from "../images/icons/Fintech/HeavilyRegulatedIndustry.svg"
import ReachandVisibility1 from "../images/icons/Fintech/ReachandVisibility1.svg"
import marketingAutomation from "../images/marketing-automation.svg"
import paidMarketing from "../images/paid-marketing.svg"
import seo from "../images/seo.svg"
import webDevelopment from "../images/web-development.svg"
import "../styles/sass.css"

export default function Fintech() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Fintech | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div className="sassStyles">
          <section id="Banner" className="fintech">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>Gain the financial marketing advantage</h1>
                    <p>
                      Reach high-value customers and promote your services
                      powerfully
                    </p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="/contact-us/"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro" className="fintech">
            <div className="container">
              <h2>We are the Fintech growth accelerators</h2>
              <p>
                In the fast-paced financial services and Fintech world, we
                deliver strategic marketing to drive results. Our proven
                strategies combine cutting-edge tactics, data-driven insights,
                and a holistic approach to ensure maximum impact, from
                compliance-focused messaging to driving adoption of innovative
                products. Partner with us to outmaneuver your competitors,
                unlock new revenue streams, and gain the financial marketing
                advantage now.
              </p>
            </div>
          </section>
          <section id="WeDeliver" className="fintech">
            <div className="container">
              <h2>Overcome your financial marketing hurdles</h2>
              <p>
                We dive deep into your Fintech business dynamics, technology
                ecosystem, and buyer mindsets. Armed with this 360-degree view,
                we devise integrated growth strategies that resonate with
                financial decision-makers.
              </p>
              <p>
                <strong>
                  Our battle-tested solutions will directly address the distinct
                  challenges you face:
                </strong>
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={HeavilyRegulatedIndustry}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Heavily Regulated Industry</h3>
                      <p>
                        Our experience helps you navigate regulatory
                        requirements seamlessly.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={ReachandVisibility1} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Reach and Visibility</h3>
                      <p>
                        Expand your presence by engaging customers across
                        channels.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={Differentiation} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Differentiation</h3>
                      <p>
                        We showcase your unique value through strategic
                        messaging and positioning.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={CustomerAcquisition} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Customer Acquisition</h3>
                      <p>
                        Attract and retain more customers through targeted
                        campaigns.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="IndustryLeaders">
            <div className="container">
              <div className="innerWrap">
                <h2 className="title">Proven for industry leaders</h2>
                <div className="logos fintech">
                  <img src={Amex} className="Amex" />
                  <img src={creditstrong} className="creditstrong" />
                  <img src={taxslayer} className="taxslayer" />
                </div>
              </div>
            </div>
          </section>
          <section id="OurServices">
            <div className="container">
              <h2>Our end-to-end services</h2>
              <div className="tilesWrap">
                <a className="eachtiles" href="/web-design-and-development/">
                  <img src={webDevelopment} />
                  <span>Website Development</span>
                </a>
                <a className="eachtiles" href="/creative-assets/">
                  <img src={creativeContent} />
                  <span>Creative & Content</span>
                </a>
                <a className="eachtiles" href="/paid-marketing/">
                  <img src={paidMarketing} />
                  <span>Paid Advertising</span>
                </a>
                <a className="eachtiles" href="/seo/">
                  <img src={seo} />
                  <span>SEO</span>
                </a>
                <a className="eachtiles" href="/marketing-automation/">
                  <img src={marketingAutomation} />
                  <span>Marketing Automation</span>
                </a>
              </div>
              <h2>Maximize your potential and fuel your Fintech growth</h2>
              <p>
                We empower you with the marketing edge to unlock exponential
                growth. Let's chart your path to success together as we combine
                expertise and innovation to propel you forward.
              </p>
            </div>
          </section>
          <section id="contactus-section" className="fintech">
            <div className="container">
              <h2>Drive measurable financial marketing results</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
